//
// pricing.js
// Theme module
//

import { CountUp } from 'countup.js';

const periodToggle = document.querySelector('[data-toggle="price"]');
const currencyToggle = document.querySelector('[data-toggle="currency"]');

if (periodToggle) {
  periodToggle.addEventListener('change', (e) => {
    const isAnnual = periodToggle.checked;

    document.querySelectorAll('.price').forEach(target => {
      const options = target.dataset.options ? JSON.parse(target.dataset.options) : {};

      options.startVal = isAnnual ? target.dataset.annual : target.dataset.monthly;
      options.duration = options.duration ? options.duration : 1;

      const countUp = isAnnual
        ? new CountUp(target, target.dataset.monthly, options)
        : new CountUp(target, target.dataset.annual, options);

      countUp.start();
    });
  });
}

if (currencyToggle) {
  currencyToggle.addEventListener('change', (e) => {
    const isUSD = !currencyToggle.checked;
    document.querySelectorAll('.currency').forEach(target => {
      target.textContent = isUSD ? '$' : '€';
    });
  });
}
